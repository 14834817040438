import classNames from 'classnames';
import React from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useWindowScrollInfo } from 'simoneconti-react/src/util';
import { Context } from './App';

interface Props {}

const Header = (props: Props) => {
  const context = React.useContext(Context);
  const windowScrollInfo = useWindowScrollInfo(10);

  interface NavLinkProps {
    label: string;
    to: string;
  }
  const NavLink = (props: NavLinkProps) => (
    <LinkContainer to={props.to}>
      <Nav.Link>{props.label}</Nav.Link>
    </LinkContainer>
  );

  interface NavDropdownItemProps {
    label: string;
    to: string;
  }
  const NavDropdownItem = (props: NavDropdownItemProps) => (
    <LinkContainer to={props.to}>
      <NavDropdown.Item>{props.label}</NavDropdown.Item>
    </LinkContainer>
  );

  return React.useMemo(
    () => (
      <Navbar
        id="main-navbar"
        variant="light"
        bg="white"
        expand="sm"
        fixed="top"
        className={classNames('border-bottom', { scrolled: windowScrollInfo.scrollPosition !== 0 })}
      >
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>Simone Conti</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="header-nav" />
          <Navbar.Collapse id="header-nav">
            <Nav className="ml-auto">
              <NavLink label="Articles" to="articles" />
              <NavDropdown title="Utilities" id="header-utilities-dropdown">
                <NavDropdownItem label="Image resizer" to="image-resizer" />
              </NavDropdown>
              <NavLink label="About me" to="about-me" />
              {context.userContext.isLogged() && (
                <NavDropdown title="Cose mie" id="header-cose-mie-dropdown">
                  <NavDropdownItem label="Eventi" to="events" />
                  <NavDropdownItem label="Download" to="download" />
                  <NavDropdownItem label="Domande da colloquio" to="job-interview-questions" />
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    ),
    [context.userContext, windowScrollInfo.scrollPosition],
  );
};

Header.defaultProps = {};
export default Header;
