import _events from './events';
import _session from './session';
import _urls from './urls';

const path = process.env.REACT_APP_REST_BASE_URL as string;

export const build = () => path;

export const session = _session(path);

export const events = _events(path);

export const urls = _urls(path);
