import { useField } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import { FormContext } from './Form';
import { BaseProps } from './util';

interface CommonProps<T> {
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
  autofocus?: boolean;
  onChange?: React.ChangeEventHandler<T>;
}

interface Props extends CommonProps<any>, BaseProps<any> {
  label?: string; // for checkbox/radio
  type?: 'text' | 'email' | 'password' | 'file' | 'checkbox' | 'radio' | 'number' | 'date' | 'range'; // for input
  as: 'input' | 'select' | 'textarea';
  inline?: boolean;
  placeholder?: string;
  rows?: number;
  min?: number | string;
  max?: number | string;
  step?: number;
  options?: {
    label: string;
    value: string;
  }[];
  includeEmptyOption?: boolean;
  custom?: boolean;
}

export const FormField = (props: Props) => {
  const [field, meta] = useField(props);
  const formContext = React.useContext(FormContext);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      field.onChange(event);
      if (props.onChange) {
        props.onChange.call(undefined, event);
      }
    },
    [field, props.onChange],
  );

  const Feedback = <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>;
  const isValid = formContext.showValidFeedback && meta.touched && !meta.error;
  const isInvalid = meta.touched && !!meta.error;

  return React.useMemo(
    () => (
      <>
        {props.type === 'checkbox' || props.type === 'radio' ? (
          <Form.Check inline={props.inline} custom={props.custom} className={props.className}>
            <Form.Check.Input
              id={props.id}
              name={field.name}
              value={field.value}
              type={props.type}
              checked={field.checked}
              autoFocus={props.autofocus}
              disabled={props.disabled}
              readOnly={props.readOnly}
              onChange={handleChange}
              onBlur={field.onBlur}
              isValid={isValid}
              isInvalid={isInvalid}
              onClick={props.onClick}
            />
            {props.label && <Form.Check.Label htmlFor={props.id}>{props.label}</Form.Check.Label>}
          </Form.Check>
        ) : props.as === 'textarea' ? (
          <>
            <Form.Control
              as="textarea"
              id={props.id}
              className={props.className}
              name={field.name}
              value={field.value}
              type={props.type}
              rows={props.rows}
              placeholder={props.placeholder}
              autoFocus={props.autofocus}
              disabled={props.disabled}
              readOnly={props.readOnly}
              onChange={handleChange}
              onBlur={field.onBlur}
              isValid={isValid}
              isInvalid={isInvalid}
            />
            {Feedback}
          </>
        ) : props.as === 'select' ? (
          <>
            <Form.Control
              as="select"
              id={props.id}
              className={props.className}
              name={field.name}
              value={field.value}
              placeholder={props.placeholder}
              autoFocus={props.autofocus}
              disabled={props.disabled}
              readOnly={props.readOnly}
              onChange={handleChange}
              onBlur={field.onBlur}
              isValid={isValid}
              isInvalid={isInvalid}
            >
              {props.options && (
                <>
                  {props.includeEmptyOption && <option></option>}
                  {props.options.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </>
              )}
            </Form.Control>
            {Feedback}
          </>
        ) : (
          <>
            <Form.Control
              as="input"
              id={props.id}
              className={props.className}
              name={field.name}
              value={field.value}
              type={props.type}
              min={props.min}
              max={props.max}
              step={props.step}
              placeholder={props.placeholder}
              autoFocus={props.autofocus}
              disabled={props.disabled}
              readOnly={props.readOnly}
              onChange={handleChange}
              onBlur={field.onBlur}
              isValid={isValid}
              isInvalid={isInvalid}
              onClick={props.onClick}
              custom={props.type === 'range'}
            ></Form.Control>
            {Feedback}
          </>
        )}
      </>
    ),
    [
      Feedback,
      field.checked,
      field.name,
      field.onBlur,
      field.value,
      handleChange,
      isInvalid,
      isValid,
      props.as,
      props.autofocus,
      props.className,
      props.custom,
      props.disabled,
      props.id,
      props.includeEmptyOption,
      props.inline,
      props.label,
      props.max,
      props.min,
      props.onClick,
      props.options,
      props.placeholder,
      props.readOnly,
      props.rows,
      props.step,
      props.type,
    ],
  );
};

FormField.defaultProps = {
  custom: true,
};

interface TextInputProps extends CommonProps<HTMLInputElement>, BaseProps<HTMLInputElement> {
  type: 'text' | 'email' | 'password';
  placeholder?: string;
}
export const TextInput = (props: TextInputProps) => {
  return <FormField {...props} as="input" />;
};

interface NumberInputProps extends CommonProps<HTMLInputElement>, BaseProps<HTMLInputElement> {
  min?: number;
  max?: number;
  step?: number;
  placeholder?: string;
}
export const NumberInput = (props: NumberInputProps) => {
  return <FormField {...props} as="input" type="number" />;
};

interface DateInputProps extends CommonProps<HTMLInputElement>, BaseProps<HTMLInputElement> {
  min?: string;
  max?: string;
  step?: number;
  placeholder?: string;
}
export const DateInput = (props: DateInputProps) => {
  return <FormField {...props} as="input" type="date" />;
};

interface SelectProps extends CommonProps<HTMLSelectElement>, BaseProps<HTMLSelectElement> {
  options: {
    label: string;
    value: string;
  }[];
  includeEmptyOption?: boolean;
}
export const Select = (props: SelectProps) => {
  return <FormField {...props} as="select" />;
};

// export interface FileInputProps extends CommonProps {}
// export const FileInput = (props: FileInputProps) => {
//   return <FormGroup {...props} as="input" type="file" />;
// };

interface TextAreaProps extends CommonProps<HTMLTextAreaElement>, BaseProps<HTMLTextAreaElement> {
  rows: number;
}
export const TextArea = (props: TextAreaProps) => {
  return <FormField {...props} as="textarea" />;
};

interface CheckboxProps extends CommonProps<HTMLInputElement>, BaseProps<HTMLInputElement> {
  id: string;
  label?: string;
  inline?: boolean;
  custom?: boolean;
}
export const Checkbox = (props: CheckboxProps) => {
  return <FormField {...props} as="input" type="checkbox" />;
};

interface RadioProps extends CommonProps<HTMLInputElement>, BaseProps<HTMLInputElement> {
  id: string;
  label?: string;
  inline?: boolean;
  value: string;
  custom?: boolean;
}
export const Radio = (props: RadioProps) => {
  return <FormField {...props} as="input" type="radio" />;
};

interface RangeInputProps extends CommonProps<HTMLInputElement>, BaseProps<HTMLInputElement> {
  min?: number;
  max?: number;
  step?: number;
}
export const RangeInput = (props: RangeInputProps) => {
  return <FormField {...props} as="input" type="range" />;
};
